.top-left {
  .back-latest-job {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
}
.top-right {
  display: flex;
  .threshold {
    display: flex;
    margin-right: 10px;
    align-items: center;
    font-size: 16px;

    .threshold-inputNumber {
      border-radius: 5px;
      width: 70px;
      margin-left: 10px;
    }
  }

  .filter {
    margin-right: 10px;

    .filter-button {
      width: 160px;
      border-radius: 5px;
    }

    .filter-button:active {
      background-color: #fff;
      border-color: #d9d9d9;
      color: #262626;
    }
  }

  .status {
    margin-right: 10px;

    .status-single-select {
      width: 245px;
      border-radius: 5px;
    }
  }
}
.report-name {
  padding: 5px 47px;
  font-size: 16px;
}
.sample-modal {
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@ant-prefix: mds-ant;